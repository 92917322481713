import GADataLayer from '../../core/libs/ga-data-layer'

export default class Form {
  static name = 'form'

  constructor (el, eventBus) {
    this.el = el
    this.eventBus = eventBus

    this.form = this.el.dataset.formSelector ? document.querySelector(this.el.dataset.formSelector) : this.el
    this.fields = this.form.querySelectorAll('.form__field')
    this.errors = [...el.querySelectorAll('.form__errors [data-target]')]

    this.focusFields = this.el.querySelectorAll(
      'select.form__field, input[type="text"].form__field, input[type="email"].form__field'
    )

    this.vacancyOptionFields = this.el.querySelectorAll(
      'input[name="location_option"], input[name="contract_option"], input[name="hours_option"]'
    )
    this.screeningQuestionFields = this.el.querySelectorAll('.form__options.form__options--radio-list')

    this.clickFields = []

    this.vacancyOptionFields.forEach((field) => this.clickFields.push(field))
    this.screeningQuestionFields.forEach((field) => this.clickFields.push(field))

    this.applyButton = this.el.querySelector('#id_apply')

    this.lang = document.querySelector('html').getAttribute('lang')
    this.ga = new GADataLayer()

    this.handleServerSideErrors(el)

    this.init()
  }

  init () {
    this.fields.forEach((fld) => {
      // Add event listeners to fields
      fld.addEventListener('focus', this.handleOnFocus.bind(this)) // Remove error messages if any
      fld.addEventListener('blur', this.handleOnBlur.bind(this)) // Validate field and handle progress indicator
      fld.addEventListener('change', this.handleOnChange.bind(this)) // Validate field on change
    })
    this.clickFields.forEach((fld) => fld.addEventListener('click', this.handleOnClick)) // Separate tracking events for fields that are always valid

    if (this.applyButton) {
      // Move bus to end of the road after user clicks submit
      this.applyButton.addEventListener('click', () => {
        this.eventBus.emit('move-bus-to-finish-line')
      })
    }

    this.eventBus.emit('update-progress-indicator', this.fields)
    this.eventBus.on('validate-field', this.validateField.bind(this))
  }

  handleServerSideErrors (el) {
    this.errors = [...el.querySelectorAll('.form__errors [data-target]')]

    // Clicking on an error message in the summary will scroll to the respective field
    this.errors.forEach((item) => {
      item.addEventListener('click', (e) => {
        e.preventDefault()
        const elm = this.el.querySelector('.form__row--' + item.dataset.target)
        const stickyBar = document.querySelector('.sticky-bar.sticky-bar--top.sticky-bar--visible')
        if (stickyBar) {
          window.scrollTo({
            top: elm.getBoundingClientRect().top + window.scrollY - stickyBar.getBoundingClientRect().height - 16,
            behavior: 'smooth'
          })
        } else {
          elm.scrollIntoView({ behavior: 'smooth' })
        }
        const input = elm.querySelector('input')
        if (input) input.focus()
        this.eventBus.emit('form-error-clicked', elm)
      })
    })

    // Scroll to error summary, if available
    if (this.errors.length > 0) {
      this.el.querySelector('.form__errors').scrollIntoView({ behavior: 'smooth' })
    }
  }

  handleOnFocus (e) {
    // Remove error messages if any
    const fld = e.target
    if (fld.required) {
      fld.classList.remove('form__field--is-valid', 'form__field--is-invalid')
    }
  }

  handleOnClick = (e) => {
    const field = e.target

    // TODO: Does not work on 'hours_option' field, hence this if statement
    if (field.closest('.form__row').querySelector('.form__label label')) {
      const event = {
        status: 'success',
        question: field.closest('.form__row').querySelector('.form__label label').innerText,
        answer: e.target.value,
      }

      this.ga.push(`input_${field.name}`, event)
    }
  }

  handleOnBlur = (e) => {
    this.eventBus.emit('update-progress-indicator', this.fields)
    this.validateField(e)
  }

  handleOnChange = (e) => {
    const fld = e.target
    if (fld.type === 'file') {
      this.validateField(e)
    }
  }

  validateField (e) {
    const fld = e.target
    const rule = fld.dataset.rules
    const requiredMessage = fld.closest('.form__row').querySelector('[data-error-type="required"]')
    const invalidMessage = fld.closest('.form__row').querySelector('[data-error-type="invalid"]')
    const generalMessage = fld.closest('.form__row').querySelector('.form__error.form__error--field')
    let isValidField = false

    // Required fields should always be filled
    const isFilled = this.isFilled(fld)

    if (fld.required) {
      fld.classList.toggle('form__field--is-invalid', !isFilled)
      fld.classList.toggle('form__field--is-valid', isFilled)

      isValidField = isFilled

      // Handle server-side generated errors
      if (requiredMessage !== null) {
        requiredMessage.classList.toggle('form__error--hidden', isFilled)
        invalidMessage?.classList.toggle('form__error--hidden', true)
      } else {
        if (generalMessage) {
          generalMessage.classList.toggle('form__error--hidden', isFilled)
        }
      }
    } else {
      // Optional fields are valid by default
      isValidField = true
    }

    if (isFilled && rule === 'email') {
      // Validates form field, if invalid, sets error and displays it

      // Validate email field
      const hasTopLevelDomain = this.hasTopLevelDomain(fld.value) // Ends with .com, .nl, etcetera
      const isValidEmail = this.isValidEmail(fld.value) // Django's email validator
      const whiteList = 'test@example' // Always allowed
      isValidField = false

      // Set specific error message
      if (!hasTopLevelDomain && fld.value != whiteList) {
        if (this.lang && this.lang === 'nl') {
          invalidMessage.textContent = 'Moet eindigen op bijvoorbeeld .nl, .com, of .net.'
        }
      } else if (!isValidEmail) {
        if (this.lang && this.lang === 'nl') {
          invalidMessage.textContent = 'Oeps - geen valide email adres.'
        }
      } else {
        // No issue found, field is valid
        isValidField = true
      }

      // Show/hide error message element
      fld.classList.toggle('form__field--is-invalid', !isValidField)
      fld.classList.toggle('form__field--is-valid', isValidField)

      // Render specific error message if available, else the global message
      if (invalidMessage !== null) {
        invalidMessage.classList.toggle('form__error--hidden', isValidField)
      } else {
        generalMessage.classList.toggle('form__error--hidden', isValidField)
      }
    }

    if (isFilled && rule === 'phone') {
      // Validate form field, if invalid, generate error

      isValidField = false
      let phone_number = fld.value

      phone_number = phone_number.replace(/-/g, '') // Ignore dashes
      phone_number = phone_number.replace(/\s/g, '') // Ignore spaces

      const isValidPhone = this.isValidPhone(phone_number)

      // Verify phone number length and generate error
      if (!isValidPhone) {
        if (this.lang && this.lang === 'nl') {
          const phoneRegex_too_short = /^(\+310?6|00310?6|0?6)\d{1,7}$|^([^06+]\d{1,6})$/
          const phoneRegex_too_long = /^(\+310?6|00310?6|0?6)\d{9,}$|^([^06+]\d{8,})$/
          if (phoneRegex_too_short.test(phone_number)) {
            invalidMessage.textContent = 'Cijfertje te weinig?'
          } else if (phoneRegex_too_long.test(phone_number)) {
            invalidMessage.textContent = 'Cijfertje teveel?'
          }
        }
      } else {
        // No issue found, field is valid
        isValidField = true
      }

      // Show/hide error message element
      fld.classList.toggle('form__field--is-invalid', !isValidField)
      fld.classList.toggle('form__field--is-valid', isValidField)

      // Render specific error message if available, else the global message
      if (invalidMessage !== null) {
        invalidMessage.classList.toggle('form__error--hidden', isValidField)
      } else {
        generalMessage.classList.toggle('form__error--hidden', isValidField)
      }
    }

    if (isFilled && rule === 'intl-phone') {
      const iti = window.intlTelInputGlobals.getInstance(fld)
      isValidField = iti.isValidNumber()
      fld.classList.toggle('form__field--is-invalid', !isValidField)
      fld.classList.toggle('form__field--is-valid', isValidField)
      // Render specific error message if available, else the global message
      if (invalidMessage !== null) {
        invalidMessage.classList.toggle('form__error--hidden', isValidField)
      } else {
        generalMessage.classList.toggle('form__error--hidden', isValidField)
      }
    }

    // Initialize Google Analytics event
    const event = { status: isValidField ? 'success' : 'error' }

    if (fld.tagName === 'SELECT') {
      event['question'] = fld.parentElement.querySelector('.form__label').textContent
      event['answer'] = e.target.value
    }

    this.ga.push(`input_${fld.name}`, event)
  }

  isFilled (fld) {
    return fld.checked || fld.value !== ''
  }

  isValidEmail (value) {
    // Returns true if a valid email is detected

    // The manual on valid email addresses:
    // https://datatracker.ietf.org/doc/html/rfc6854
    //
    // So, let's not reinvent the wheel, and instead stand on the shoulders of giants:
    // https://docs.djangoproject.com/en/4.0/_modules/django/core/validators/#EmailValidator

    if (!value || !value.includes('@')) {
      return false
    }

    const [userPart, domainPart] = value.split('@')
    const userRegex = /(^[-!#$%&'*+/=?^_`{}|~0-9A-Z]+(.[-!#$%&'*+/=?^_`{}|~0-9A-Z]+)*$)/i
    const domainRegex = /((?:[A-Z0-9](?:[A-Z0-9-]{0,61}[A-Z0-9])?.)+)(?:[A-Z0-9-]{2,63})$/i

    return userRegex.test(userPart) && domainRegex.test(domainPart)
  }

  hasTopLevelDomain (value) {
    // Returns true if a toplevel domain is detected

    // Matches 2 to 10 characters after a period, at the end of a line
    //
    // Matches:
    //
    // .nl
    // .com
    const topLevelDomainRegex = /\.\w{2,10}$/i

    return topLevelDomainRegex.test(value)
  }

  isValidPhone (value) {
    let phoneRegex
    // Returns true if a valid phone number is detected
    if (this.lang && this.lang === 'nl') {
      // NL locale

      // Either:
      //
      // Match prefix (optional):
      //
      // 06
      // +316
      // +3106
      // 00316
      // 003106
      //
      // Match phone number digits (exact, required): 8
      //
      // Or:
      //
      // Match any number that does not start with 0 or 6, and
      // has exactly 7 additional characters (so 8 in total)
      phoneRegex = /^(\+310?6|00310?6|0?6)\d{8}$|^([^06+]\d{7})$/
    } else {
      // All other locales
      phoneRegex = /^[+0-9 ]{7,16}$/
    }
    return phoneRegex.test(value)
  }
}
